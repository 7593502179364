export const KEY_CODES = {
    ENTER: 13,
    ESCAPEKEY: 27,
    LEFT_ARROW: 37,
    RIGHT_ARROW: 39,
};

export const KEY_VALUES = {
    ENTER: "Enter",
    ESCAPEKEY: "Escape",
    LEFT_ARROW: "ArrowLeft",
    RIGHT_ARROW: "ArrowRight",
};

export const CMS_WAP_URL_ROOT = "https://wap.fht360.com";