import CryptoJS from 'crypto-js';

// 密钥
const key = 'iamkey';
const keyMD5 = CryptoJS.MD5(key).toString();
const keyHex = CryptoJS.enc.Hex.parse(keyMD5);
// 密钥偏移量
const iv = 'iamiv';
const ivMD5 = CryptoJS.MD5(iv).toString();
const ivHex = CryptoJS.enc.Hex.parse(ivMD5);

export const encrypt = (rawText: string): string => {
  const result = CryptoJS.AES.encrypt(rawText, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return result.ciphertext.toString();
};

export const decrypt = (encryptText: string): string => {
  const encryptTextHex = CryptoJS.enc.Hex.parse(encryptText);
  const encryptTextBase64 = CryptoJS.enc.Base64.stringify(encryptTextHex);
  const result = CryptoJS.AES.decrypt(encryptTextBase64, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return result.toString(CryptoJS.enc.Utf8);
};
