import { IS_DEV } from "./env";
import { exceptionless } from "./exceptionless";
import { cmsGlobal, DEBUG_LOG_ERROR, LOG_ERROR_ENABLED } from "./fhtGlobalEnv";

export function assert(condition: unknown, message?: string, tag?: string): asserts condition {
	if (!condition) {
		try {
			const errorMessage = message || "assert " + condition + " failed";
			throw new Error(errorMessage);
		} catch (err) {
			if (exceptionless && LOG_ERROR_ENABLED) {
				let ex = exceptionless.createException(err as Error);
				const tags = ["assert", cmsGlobal.Env.AppEnv!];
				if (tag) {
					tags.push(tag);
					ex = ex.setManualStackingKey(tag);
				}
				ex.addTags(...tags);
				ex.submit();
			}
			if (IS_DEV) {
				throw err;
			}
		}
	}
}

if (DEBUG_LOG_ERROR) {
	//@ts-ignore
	window.assert = assert;
}