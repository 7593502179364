export enum CustomJqueryEvents {
    ActionableBatchIdsEvent = "actionableBatchIds.fht360",
    AjaxLoadedHtmlEvent = "ajaxLoadedHtml.fht360",
    /** 在CMS左侧配置的里面改变模块组里面的模块的时候，
     * 右侧的预览iframe里面的模板页面需要做及时更新（也就是不刷新页面，局部更新)，
     * 有一些使用了jquery插件的模块就需要重新初始化
     * 此时，就可以注册这个事件*/
    CmsModuleContentUpdatedEvent = "cmsModuleContentUpdated.fht360",
    TopbarFixedEvent = "topbarFixed.fht360",
    TopbarUnfixedEvent = "topbarUnfixed.fht360",
    ScrollEvent = "scroll.fht360",
    SuccessfullyLoggedInEvent = "successfullyLoggedIn.fht360",
    ValidationResultChangedEvent = "validationResultChanged.fht360",
}
