import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel";

export interface SlickOption {
    accessibility?: boolean;
    adaptiveHeight?: boolean;
    autoplay?: boolean;
    autoplaySpeed?: number;
    arrows?: boolean;
    asNavFor?: Element | JQuery | string;
    appendArrows?: Element | Element[] | JQuery | string | boolean;
    appendDots?: Element | Element[] | JQuery | string;
    prevArrow?: Element | JQuery | string;
    nextArrow?: Element | JQuery | string;
    centerMode?: boolean;
    centerPadding?: string;
    cssEase?: string;
    customPaging?: (slider: any, i: number) => string;
    dots?: boolean;
    dotsClass?: string;
    draggable?: boolean;
    fade?: boolean;
    focusOnSelect?: boolean;
    easing?: string;
    edgeFriction?: number;
    infinite?: boolean;
    initialSlide?: number;
    lazyLoad?: string;
    mobileFirst?: boolean;
    pauseOnFocus?: boolean;
    pauseOnHover?: boolean;
    pauseOnDotsHover?: boolean;
    respondTo?: string;
    responsive?: Record<string, any>;
    rows?: number;
    slide?: string;
    slidesPerRow?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    speed?: number;
    swipe?: boolean;
    swipeToSlide?: boolean;
    touchMove?: boolean;
    touchThreshold?: number;
    useCSS?: boolean;
    useTransform?: boolean;
    variableWidth?: boolean;
    vertical?: boolean;
    verticalSwiping?: boolean;
    rtl?: boolean;
    waitForAnimate?: boolean;
    zIndex?: number;
}

interface JQuerySlickEl {
    slick(method: "slick" | "slickPrev" | "slickNext" | "slickPause" | "slickPlay"): void;
    slick(method: "slickSetOption", option: string, value: any, refresh: boolean): void;
    slick(method: "slickGoTo", index: number): void;
    slick(metho: "slickCurrentSlide"): void;
}

export class Slick {
    private readonly $el: JQuery & JQuerySlickEl;

    constructor($el: JQuery, opts: SlickOption) {
        this.$el = ($el as any).slick(opts);
    }

    public slickPrev() {
        this.$el.slick("slickPrev");
    }
    public slickNext() {
        this.$el.slick("slickNext");
    }
    public slickPause() {
        this.$el.slick("slickPause");
    }
    public slickPlay() {
        this.$el.slick("slickPlay");
    }
    public slickSetOption(option: string, value: any, refresh: boolean) {
        this.$el.slick("slickSetOption", option, value, refresh);
    }
    public slickGoTo(index: number) {
        this.$el.slick("slickGoTo", index);
    }
    public slickCurrentSlide() {
        this.$el.slick("slickCurrentSlide");
    }
    public onAfterChange(
        handler: (event: JQueryEventObject, slick: any, currentSlide: number, nextSlide: number) => void,
    ) {
        this.$el.on("afterChange", handler);
    }
    public onBeforeChange(
        handler: (event: JQueryEventObject, slick: any, currentSlide: number, nextSlide: number) => void,
    ) {
        this.$el.on("beforeChange", handler);
    }
    public onSlickSetPosition(handler: (event: JQueryEventObject, slick: any) => void) {
        this.$el.on("slickSetPosition", handler);
    }
}

export function slickNext($el: JQuery) {
    (($el as unknown) as JQuerySlickEl).slick("slickNext");
}
