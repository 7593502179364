import { encrypt } from "@/aes";
import { CmsApiClient } from "@/api";
let cmsApi = new CmsApiClient("");

export function genPath(phoneText: string, fontColor: string): string {
    return `http://api.fht360.cn/phone/${encrypt(
        phoneText,
    )}.png?fontSize=14&color=${fontColor}&fontRotate=false&fontScale=false&uglifyLine=false&uglifyDot=false`;
}

export function packageTel(telephone: string, imgPath: string): string {
    return `<a><img src="${imgPath}" width="86" height="18" /></a>`;
}

export const loadContact = (): void => {
    const requiredAnalysisMobileLength = $(".analysis-mobile").length;
    if (requiredAnalysisMobileLength > 0) {
        $(".analysis-mobile").each(function () {
            let _this = $(this);
            let val = _this.data("val");
            let fontColor = _this.data("fontcolor");
            let res = cmsApi.analysisPhoneNumber({
                EncryptionText: val,
            });
            res.then((val) => {
                var tel = packageTel(val, genPath(val, fontColor));
                _this.append(tel);
            });
        });
    }

    const requiredAnalysisTelLength = $(".analysis-tel").length;
    if (requiredAnalysisTelLength > 0) {
        $(".analysis-tel").each(function () {
            let _this = $(this);
            let val = _this.data("val");
            let fontColor = _this.data("fontcolor");
            let res = cmsApi.analysisPhoneNumber({
                EncryptionText: val,
            });
            res.then((val) => {
                var tel = packageTel(val, genPath(val, fontColor));
                _this.append(tel);
            });
        });
    }
};
