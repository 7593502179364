import { assert } from "@/common/assert";

$(async () => {
    const $containers = $(".contact-us-map");
    if ($containers.length) {
        assert($containers.length === 1, "more than 1 contact-us-map??");
        const { loadBaiduMap } = await import(/* webpackChunkName: "baiduMap" */ "./baiduMap");
        await loadBaiduMap($containers.get(0));
    }
});
