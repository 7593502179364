import "jquery-aniview";

interface IAniViewConfig {
    animateThreshold: number;
    scrollPollInterval?: number;
}

function createAniView($el: JQuery, config?: IAniViewConfig): JQuery {
    return ($el as any).AniView(config);
}

export { createAniView };
