import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations/esm/captureconsole";
import { Dedupe } from "@sentry/integrations/dist/dedupe";

import { cmsGlobal, DEBUG_LOG_ERROR, IS_PROD, LOG_ERROR_ENABLED } from "@/common/fhtGlobalEnv";
import { Severity } from "@sentry/browser";
import { exceptionless } from "@/common/exceptionless";

if (LOG_ERROR_ENABLED) {
	// see https://sentry.io/organizations/fht/issues/?project=122385
	// see https://docs.sentry.io/platforms/javascript/?_ga=2.244892357.1383536761.1600938395-1689444901.1600938395
	const IMPORTANT_SERVERITIES: Severity[] = [Severity.Critical, Severity.Error, Severity.Critical, Severity.Warning];

	Sentry.init({
		dsn: "https://we_are_posting_to_exceptionless_instead@o57062.ingest.sentry.io/122385",
		//see all integrations: https://docs.sentry.io/platforms/javascript/configuration/integrations/
		integrations: defaultIntegrations => {
			return [
				...defaultIntegrations,
				new CaptureConsole({
					levels: ["warn", "error", "assert"],
				}),
				new Dedupe(),
			];
		},
		release: "cms-client-" + cmsGlobal.Env.AppVersion,
		environment: cmsGlobal.Env.AppEnv,
		allowUrls: ["fht", "localhost"],
		debug: DEBUG_LOG_ERROR,
		//see https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
		//It ignores errors that start with Script error or Javascript error: Script error by default.
		ignoreErrors: [],
		tracesSampleRate: 1,
		async beforeSend(event, hint) {
			// if (DEBUG_LOG_ERROR) {
			// 	console.log("sentry event:", event);
			// 	console.log("sentry hint:", hint);
			// }
			if (!IS_PROD) {
				if (event.level && IMPORTANT_SERVERITIES.includes(event.level)) {
					$(document.body).trigger("sentry.fht360", event);
				}
			}
			if (LOG_ERROR_ENABLED) {
				const ex = hint?.originalException ?? hint?.syntheticException;
				if (ex) {
					exceptionless.createException(typeof ex === "string" ? new Error(ex) : ex)
						.addTags(event.environment!, event.logger!)
						.submit();
				} else {
					exceptionless.submitEvent({
						message: event.message,
						type: event.logger,
						tags: [event.environment!, event.logger!],
						source: event.release,
						data: {
							breadcrumbs: event.breadcrumbs,
							request: event.request,
							extra: event.extra
						}
					})
				}
			}
			return null;
		},
	});
}
