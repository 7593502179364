import { assert } from "@/common/assert";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { CmsPresetPage } from "@/api";
import { IS_DEV } from "@/common/env";

const companyHost = IS_DEV ? "" : cmsGlobal.Env.Hosts.FhtWebCompanyRoot;

export function cmsHomeUrl(companyId: number): string {
    assert(companyId, "companyId falsy");
    return companyHost + `/c${companyId}`;
}

export function cmsProductsUrl(companyId: number): string {
    assert(companyId, "companyId falsy");
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    return companyHost + `/c${companyId}/product`;
}

export function cmsProductSearchUrl(companyId: number, q: string): string {
    return companyHost + `/c${companyId}/product?q=${encodeURIComponent(q)}`;
}

export function productSearchUrl(q: string): string {
    return cmsGlobal.Env.Hosts.FhtWebUrlRoot + `/psearch?Q=${encodeURIComponent(q)}`;
}

export function cmsPresetArticleUrl(companyId: number, presetPage: CmsPresetPage): string {
    assert(companyId, "companyId falsy");
    return companyHost + `/c${companyId}/${presetPage}`;
}

export function cmsNewsUrl(companyId: number): string {
    assert(companyId, "companyId falsy");
    return `${companyHost}/c${companyId}/news`;
}

export function cmsAboutUsUrl(companyId: number): string {
    assert(companyId, "companyId falsy");
    return companyHost + `/c${companyId}/about.html`;
}

export function cmsContactUsUrl(companyId: number): string {
    assert(companyId, "companyId falsy");
    return companyHost + `/c${companyId}/contact.html`;
}

export function productDetailUrl(productId: number): string {
    assert(productId, "productId falsy");
    return cmsGlobal.Env.Hosts.FhtWebDetailRoot + `/pro${productId}.html`;
}

export function cmsArticleDetailUrl(companyId: number, articleId: number): string {
    assert(companyId, "companyId falsy");
    assert(articleId, "articleId falsy");
    return companyHost + `/c${companyId}/n${articleId}.html`;
}

export function cmsArticleTypeUrl(companyId: number, articleTypeId: number): string {
    assert(companyId, "companyId falsy");
    assert(articleTypeId, "articleTypeId falsy");
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    return companyHost + `/c${companyId}/n${articleTypeId}`;
}

export function cmsCustomCategoryUrl(companyId: number, customCategoryId: number): string {
    assert(companyId, "companyId falsy");
    assert(customCategoryId, "customCategoryId falsy");
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    return companyHost + `/c${companyId}/c${customCategoryId}`;
}

export function cmsSystemCategoryUrl(companyId: number, systemCategoryId: number): string {
    assert(companyId, "companyId falsy");
    assert(systemCategoryId, "systemCategoryId falsy");
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    return companyHost + `/c${companyId}/s${systemCategoryId}`;
}
