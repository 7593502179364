import "./index.scss";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { assert } from "@/common/assert";
import { UrlUtils } from "@/pages/base/urlUtils";
import { cmsProductSearchUrl, productSearchUrl } from "@/pages/base/urls";

$(".searchBox").each(function () {
    assert($(this).hasClass("is-html-helper"), "all .searchBox must be .is-html-helper");
});

//将URL中的querystring赋值给searchBox__input
function inputQueryString() {
    const searchedUrl = location.href;
    /* eslint-disable compat/compat */
    const searchParams = new URL(searchedUrl).searchParams;
    if (searchParams) {
        const queryString = searchParams.get("Q");
        if (queryString) {
            $(".searchBox__input").val(queryString);
        } else {
            return;
        }
    }
}

//cms web 端搜索
/*来自_SearchBox.cshtml 的行内js*/
function searchBox() {
    const searchBoxType = $(".searchBox__select-head-val").length > 0 ? $(".searchBox__select-head-val").text() : "";
    const keyWord = $(".searchBox__input").val() as string;
    const templateId = UrlUtils.GetQueryStringByKey("templateId");
    if (searchBoxType === "搜站内" || searchBoxType === "") {
        const searchBoxUrl = cmsProductSearchUrl(cmsGlobal.Cms.CompanyId, keyWord);
        let toUrl = UrlUtils.UpdateQueryString("Q", keyWord, searchBoxUrl);
        if (templateId) {
            toUrl = UrlUtils.UpdateQueryString("templateId", templateId, toUrl);
        }
        location.href = toUrl;
    } else {
        window.open(productSearchUrl(keyWord));
    }
}

$(".searchBox__btn").click(searchBox);

$(".searchBox__input").on("keyup", function (event) {
    if (event.which === 13) {
        searchBox();
    }
});
inputQueryString();

/*********  下拉  ***********/

//初始化赋值
$(".searchBox__select-head-val").html($(".searchBox__option li").eq(0).html());

//点击弹出下拉列表
$(".searchBox__select-head").click(function (event) {
    event.stopPropagation(); //阻止冒泡~必须条件！！
    $(".searchBox__option").slideToggle();

    //箭头动画
    if ($(".searchBox__select-head-icon").hasClass("rotate")) {
        $(".searchBox__select-head-icon").addClass("rotatetoggle").removeClass("rotate");
    } else {
        $(".searchBox__select-head-icon").removeClass("rotatetoggle").addClass("rotate");
    }
});

//选中后赋值
$(".searchBox__option li").click(function () {
    $(".searchBox__select-head .searchBox__select-head-val").html(
        $(".searchBox__option li").eq($(this).index()).html(),
    );
    $(".searchBox__select-head .searchBox__select-head-icon").addClass("rotatetoggle").removeClass("rotate");
});

//点击下拉菜单之外隐藏列表
$(document).click(function () {
    $(".searchBox__option").hide();
    $(".searchBox__select-head-icon").addClass("rotatetoggle").removeClass("rotate");
});
