import { FhtCmsGlobal, KnownCmsPageType, ApplicationEnvEnum } from "@/api";

export const cmsGlobal: FhtCmsGlobal = (() => {
	const g = window.cmsGlobal;
	if (!g) throw new Error("window.cmsGlobal is falsy");
	const r = g as FhtCmsGlobal;
	if (!r.Env) throw new Error("gotta have .Env");
	if (!r.Cms) throw new Error("gotta have .Cms");
	return r;
})();

export function isKnownPage(page: KnownCmsPageType) {
	return cmsGlobal.KnownPage === page;
}

export const IS_PROD = cmsGlobal.Env.AppEnv === ApplicationEnvEnum.Production;
export const DEBUG_LOG_ERROR = Boolean(localStorage.getItem("DEBUG_LOG_ERROR"));

const IS_PROD_OR_STAGING = cmsGlobal.Env.AppEnv === ApplicationEnvEnum.Staging || IS_PROD;
const IS_PROD_OR_STAGING_OR_MOBILEDEVELOPMENT = cmsGlobal.Env.AppEnv === ApplicationEnvEnum.MobileDevelopment || IS_PROD_OR_STAGING;

export const LOG_ERROR_ENABLED = (cmsGlobal.Env.IsRelease && IS_PROD_OR_STAGING_OR_MOBILEDEVELOPMENT) || DEBUG_LOG_ERROR;

export const qrcodePath = (() => {
	return "https://img2.fht360.com/2021/3/19/14/ec03f27ed4fe466dbbd582194937ac1f-800x800.png@!large";
})();
