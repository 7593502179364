import "./index.scss";
import { CustomJqueryEvents } from "@/pages/base/customJqueryEvents";
import { assert } from "@/common/assert";
import { CmsModuleUpdatedEvent } from "fht-cms-settings-shared/dist/messages/WidgetDataUpdatedMessage";

$(".cat").each(function () {
    const $this = $(this);
    assert($this.hasClass("is-html-helper"), "all .cat must be .is-html-helper");
    assert(
        $this.hasClass("cat--pre-toggle") || $this.hasClass("cat--post-toggle"),
        "all must has class .cat---post-toggle or cat--pre-toggle",
    );
});

const toggeClass = $(".cat").hasClass("cat--post-toggle") ? ".cat__post-link" : ".cat__pre-link";
$(document).on("click", ".cat__item--has-children " + toggeClass, function () {
    const $this = $(this).parent(".cat__item");
    const isCurrChildVisible = $this.find(".cat__child").is(":visible");
    $this.toggleClass("cat__item--active-parent", !isCurrChildVisible);
    //屏蔽掉设置的默认展开
    if (isCurrChildVisible) {
        $this.removeClass("cat__item--is-expanded");
    }
});

//产品分类 toggle 多余的分类
$(document).on("click", ".cat__more", function () {
    const $this = $(this);
    const $hideElements = $this.siblings(".cat").find(".cat__item--over-limit");
    $hideElements.slideToggle(300, function () {
        if ($hideElements.is(":visible")) {
            $this.text("折叠");
        } else {
            $this.text("更多");
        }
    });
});

$(document).on(CustomJqueryEvents.CmsModuleContentUpdatedEvent, function (event, data: CmsModuleUpdatedEvent) {
    if (data.data && data.data.ModuleType === "ProductCategoryTree") {
        const target = event.target;
        console.log("Maybe you need to update ProductCategoryTree, or maybe not");
    }
});
