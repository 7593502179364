import "./dynamicPublicPath";
import "@/pages/base/sentryError";
import "./reset.scss";
import "./utility.scss";
import "./common.scss";
import "@/assets/sprites/sprite";
import "@/assets/sprites/sprite.scss";
import "./404.scss";
import "@/pages/base/styles/animations/index.scss";
import "@/templates/components/productCategory";
import "@/templates/components/searchBox";
import "@/templates/components/navigations";
import { cmsGlobal, IS_PROD } from "@/common/fhtGlobalEnv";
import { checkMobile } from "@/common/checkMobile";
import { requestIdleCallback } from "@/pages/base/requestIdleCallback";
import { UrlUtils } from "@/pages/base/urlUtils";
import { CMS_WAP_URL_ROOT } from "@/consts";
import { qrCodeGeneratorAside } from "./qrCode";
import { KnownCmsPageType } from "@/api";
import { CmsApiClient } from "@/api";
import { packageTel } from "@/phone";
import { encrypt } from "@/aes";

const cmsApi = new CmsApiClient("");

if (cmsGlobal.Cms.IsPreviewing) {
    console.log("Loading cmsSettingInstantUpdate!");
    import(/* webpackChunkName: "cmsSettingInstantUpdate" */ "./cmsSettingInstantUpdate");
} else {
    const validHosts = [cmsGlobal.Env.Hosts.FhtWebCompanyRoot!, cmsGlobal.Env.Hosts.FhtWebMobileCompanyRoot!];
    if (cmsGlobal.Env.IsRelease && !cmsGlobal.Cms.IsCustomDomain) {
        if (!validHosts.some((host) => location.href.includes(host))) {
            console.warn("invalid company host in:" + location.href, "not one of:", validHosts.join(", "));
        }
    }
}

$(document.body).on("click", "a", (e) => {
    const href = ($(e.target).attr("href") || "").toLowerCase();
    if (!href) return;
    const isCompanyHref = !href.includes("http") || href.includes(cmsGlobal.Env.Hosts.FhtWebCompanyRoot.toLowerCase());
    if (!isCompanyHref) return;
    const templateId = UrlUtils.GetQueryStringByKey("templateId", href);
    if (!templateId) return;
    $(e.target).attr("href", UrlUtils.UpdateQueryString("templateId", templateId, href));
});

export enum LanguageSettingEnum {
    Chinese = "Chinese",
    English = "English",
}

function genPath(phoneText: string, fontColor: string): string {
    return `http://api.fht360.cn/phone/${encrypt(
        phoneText,
    )}.png?fontSize=14&color=${fontColor}&fontRotate=false&fontScale=false&uglifyLine=false&uglifyDot=false`;
}

$(async () => {
    if (!IS_PROD) {
        const { installCmsDebug } = await import(/* webpackChunkName: "cmsDebug" */ "./cmsDebug");
        installCmsDebug();
    }
    const notMobile = !checkMobile();
    const templateId = UrlUtils.GetQueryStringByKey("templateId", location.href);
    const isTa10Home = templateId === "template_advanced10" && cmsGlobal.KnownPage === KnownCmsPageType.CmsHome;
    if (notMobile && !isTa10Home) {
        requestIdleCallback(async () => {
            console.log("Loading fhtweb/chatAppInstaller from remote when idle");
            //@ts-ignore
            const { chatAppInstaller } = await import("fhtweb/chatAppInstaller");
            await chatAppInstaller();

            //broadside tool qrcode
            const wapHomeUrl = `${CMS_WAP_URL_ROOT}/c${cmsGlobal.Cms.CompanyId}`;
            if ($(".cms-qrcode--aside").length === 1) {
                qrCodeGeneratorAside(wapHomeUrl);
            }

            //broadside tool contacts
            const $contactNumbers = $(".broadside-tool__tel-numbers");
            const $contacts = $(".common-contacts-list__item");
            if ($contacts) {
                const contactsLen = $contacts.length;
                $contacts.each(function (index, el) {
                    let _this = $(this);
                    let encryptedPhoneNumber = el.textContent;
                    if (encryptedPhoneNumber) {
                        cmsApi
                            .analysisPhoneNumber({
                                EncryptionText: encryptedPhoneNumber,
                            })
                            .then((val) => {
                                let fontColor = _this.data("fontcolor");
                                const path = genPath(val, fontColor);
                                var tel = packageTel(val, path);
                                if (tel) {
                                    const html = `<div class="broadside-tool__tel-numbers-val">${tel}</div>`;
                                    $(html).appendTo($contactNumbers);
                                }
                            })
                            .catch((err) => {
                                console.error("error generating phone number image, ", err);
                            });
                    }
                });
            }
        });
    }
});
