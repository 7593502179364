//see https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
export const UrlUtils = {
    RemoveHost(url: string) {
        //courtesy of https://stackoverflow.com/questions/11550790/remove-hostname-and-port-from-url-using-regular-expression
        return url.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, "/"); // http or https
    },
    GetQueryStringByKey(name: string, url?: string) {
        //see https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
        if (!url) url = location.href;
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regexS = "[\\?&]" + name + "=([^&#]*)";
        const regex = new RegExp(regexS, "i");
        const results = regex.exec(url);
        return results === null ? null : results[1];
    },

    UpdateQueryString(key: string, value: string | number | boolean, url: string) {
        if (!url) url = window.location.href;
        const patt = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi");

        if (patt.test(url)) {
            if (typeof value !== "undefined" && value !== null)
                return url.replace(patt, "$1" + key + "=" + encodeURIComponent(value.toString()) + "$2$3");
            else {
                const hash = url.split("#");
                url = hash[0].replace(patt, "$1$3").replace(/(&|\?)$/, "");
                if (typeof hash[1] !== "undefined" && hash[1] !== null) url += "#" + hash[1];
                return url;
            }
        } else {
            if (typeof value !== "undefined" && value !== null) {
                const separator = url.indexOf("?") !== -1 ? "&" : "?";
                const hash = url.split("#");
                url = hash[0] + separator + key + "=" + encodeURIComponent(value.toString());
                if (typeof hash[1] !== "undefined" && hash[1] !== null) url += "#" + hash[1];
                return url;
            } else return url;
        }
    },
};
