import { assert } from "@/common/assert";
import { CMS_WAP_URL_ROOT } from "@/consts";
import "jquery.qrcode";

export function qrCodeGenerator(qrCodeUrl: string) {
    const $qrCodeDom = $(".cms-qrcode");
    assert($qrCodeDom.length > 0, "no qrcode dom found");
    assert(qrCodeUrl, "qrCodeUrl falsy");
    assert(qrCodeUrl.indexOf(CMS_WAP_URL_ROOT) > -1, "is not cms home url");
    const qrCodeSize = 98;
    $qrCodeDom.qrcode({
        text: qrCodeUrl,
        width: qrCodeSize,
        height: qrCodeSize,
    });
}

export function qrCodeGeneratorAside(qrCodeUrl: string) {
    const $qrCodeDom = $(".cms-qrcode--aside");
    assert($qrCodeDom.length > 0, "no qrcode dom found");
    assert(qrCodeUrl, "qrCodeUrl falsy");
    assert(qrCodeUrl.indexOf(CMS_WAP_URL_ROOT) > -1, "is not cms home url");
    const qrCodeSize = 180;
    $qrCodeDom.qrcode({
        text: qrCodeUrl,
        width: qrCodeSize,
        height: qrCodeSize,
    });
}
