export const Icons = {
    chatCloseChatHover: "icon--chat-close-chat-hover",
    chatCloseChat: "icon--chat-close-chat",
    chatFeigeGrey: "icon--chat-feige-grey",
    chatFeigeRed: "icon--chat-feige-red",
    chatFeigeWhite: "icon--chat-feige-white",
    chatSearch: "icon--chat-search",
    homeActiveCompany: "icon--home-active-company",
    homeAppreg: "icon--home-appreg",
    homeBannerArrowLeft: "icon--home-banner-arrow-left",
    homeBannerArrowRight: "icon--home-banner-arrow-right",
    homeGuid: "icon--home-guid",
    homeHeaderQrcodeIcon: "icon--home-header-qrcode-icon",
    homeHotProductIcon: "icon--home-hot-product-icon",
    homeHotPublish: "icon--home-hot-publish",
    homeNewestPublish: "icon--home-newest-publish",
    homePcreg: "icon--home-pcreg",
    homeRefreshActiveHover: "icon--home-refresh-active-hover",
    homeRefresh: "icon--home-refresh",
    homeTeamwork: "icon--home-teamwork",
    homeOfficialIntroBusinessFriend: "icon--home-official-intro-business-friend",
    homeOfficialIntroCommunication: "icon--home-official-intro-communication",
    homeOfficialIntroMyCompany: "icon--home-official-intro-my-company",
    homeOfficialIntroRecommendBusiness: "icon--home-official-intro-recommend-business",
    ta10CompanyLogo: "icon--ta-10-company-logo",
    ta10ShowMore: "icon--ta-10-show-more",
    wholeAdvancedSearch: "icon--whole-advanced-search",
    wholeBackHeaderLogo: "icon--whole-back-header-logo",
    wholeCollectActive: "icon--whole-collect-active",
    wholeCollectHover: "icon--whole-collect-hover",
    wholeCollect: "icon--whole-collect",
    wholeCompanyVerifiedIcon: "icon--whole-company-verified-icon",
    wholeDefaultavatar: "icon--whole-defaultavatar",
    wholeDownArrowRed: "icon--whole-down-arrow-red",
    wholeDownArrowWhite: "icon--whole-down-arrow-white",
    wholeDownArrow: "icon--whole-down-arrow",
    wholeDownArrowTriangle: "icon--whole-down-arrow-triangle",
    wholeEditHover: "icon--whole-edit-hover",
    wholeEdit: "icon--whole-edit",
    wholeFhtNoticeCloseHover: "icon--whole-fht-notice-close-hover",
    wholeFhtNoticeClose: "icon--whole-fht-notice-close",
    wholeForward: "icon--whole-forward",
    wholeIndexLogo: "icon--whole-index-logo",
    wholeLeftHoverArrow: "icon--whole-left-hover-arrow",
    wholeLocation: "icon--whole-location",
    wholeMemberApplyCloseHover: "icon--whole-member-apply-close-hover",
    wholeMemberApplyClose: "icon--whole-member-apply-close",
    wholeNormalUserIcon: "icon--whole-normal-user-icon",
    wholeNoticeIcon: "icon--whole-notice-icon",
    wholeRemodalCloseHover: "icon--whole-remodal-close-hover",
    wholeRemodalClose: "icon--whole-remodal-close",
    wholeReportActiveHover: "icon--whole-report-active-hover",
    wholeReport: "icon--whole-report",
    wholeRightHoverArrow: "icon--whole-right-hover-arrow",
    wholeRightSmallArrowHover: "icon--whole-right-small-arrow-hover",
    wholeRightSmallArrow: "icon--whole-right-small-arrow",
    wholeSuccessIcon: "icon--whole-success-icon",
    wholeVipBronzeIcon: "icon--whole-vip-bronze-icon",
    wholeVipGoldIcon: "icon--whole-vip-gold-icon",
    wholeVipSilverIcon: "icon--whole-vip-silver-icon",
};
    